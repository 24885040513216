import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options__btn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options__option--danger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`box-shadow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0 2px 6px 0 rgba(0, 0, 0, 0.3)`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "interactive-states"
    }}>{`Interactive states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`option:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`option:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-row`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`option:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`option--danger:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-danger`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`option:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.233695652173914%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABv0lEQVQoz3VSOUsDQRid7IpILAIpRCWFJ4IW1oqltrb2FiJ4EVEEUymokEb9KYqNjQhqoUXcwioJubNnNlnNYTySzzeLuwkeD5b5mPf2zZtvPpbL5RgRMQ5N0zyGYXhM0/Q4e6jtVVVVmy8UCjbPvgE9+xOOQTsURfE6Zj+RSqU6wHd+B2kR7Qkh8BeLxQAS+CCaw3rephNh3FsqlQLZbNYLLox0Qc5hT3ANZVl2DSE4g5AgeK7X63y9c3Q4aFDXdQsHvb0A+Ieg3eRcuVwWXcNYLMaSyaRdx+Pxy3w+T1g/ICKkunYNDWNYk+V3XVHI1PUGNZtkatqGbWhZLcNMJsMajYaT9gIpCVevVKtVQn3l6CyiIePjvazWXz9R13jCEtE656pEYnvj3SvjBW+4kF+Xw7KsiKOLLq2Oatshegntkbm1SxTap8RKcMfu79pWyzCRSLhPn06nF9GCE7TgEP06xmHLju6WMb/k6zt46h8JS13+owjrOn1g3TOce2Rez68xiEaj/47TPfsfyZ8btVqNSZJk15VKRUAfRT4GMBLxueMQ7+5hxvSsYM0vCMbklKAGxsTswISdTBsYd/2+ADP1VsIEDBXhAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Overflow menu text hover example",
          "title": "Overflow menu text hover example",
          "src": "/static/70aa89abf389b39c3de297cb2bde4279/fb070/overflow-menu-style-1.png",
          "srcSet": ["/static/70aa89abf389b39c3de297cb2bde4279/d6747/overflow-menu-style-1.png 288w", "/static/70aa89abf389b39c3de297cb2bde4279/09548/overflow-menu-style-1.png 576w", "/static/70aa89abf389b39c3de297cb2bde4279/fb070/overflow-menu-style-1.png 1152w", "/static/70aa89abf389b39c3de297cb2bde4279/c3e47/overflow-menu-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Text and warning hover examples for overflow menu</Caption>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Overflow menu text should be set in set in sentence case with the first letter of the first word capitalized.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options__btn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The height of an overflow menu is determined by the amount of content in the menu. The overflow menu icon can be found in the `}<a parentName="p" {...{
        "href": "/style/iconography/library"
      }}>{`iconography`}</a>{` library.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`icon size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 x 16px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options__btn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--overflow-menu-options__option--danger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image-fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.45652173913044%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABc0lEQVQoz2NgwAJiVq6Bs////w/GTx69Zfz//wpY7IFzG5i+qJ4KxvhBbSWY0p06A6+ytxqFDAQB2Lb/qxnW5k0B80Wbm7miNm2Q2HPthhCI/+Tas6pHZ65Fgdjvf35nuqCeQtilFzRSGaYBvQgCHZsPyJx7+vTaqxcvHnz+9Onqh9+//u9+/DgNJLf//38muCPwuhAIjsZ1sQCNZJu4dq/iuYePfr57+fr/u1dv/n8FBua6a7eSQGpOR3XwA9Vz4HUhTPCUZw37MwY3iZkz1qteePbs7avnz/+8e/v2y8e/f/9vePAwHmzpgh1sQPWMBL0Mklj14Q1YYdfOowrX3775///Pn/9/fv/+DwIbnz8D69zz/z8zQS+fhwbyum2HwfTkRVuFTl6+1nrvwpW+p9du9dy7/3jq2gtXTEByu67dYTqumsBAlJdBYOLz1zgtPgiNNKLB5v//wHQFUOPN3z+YgD5lBGGgp5m3A2mQ3HEchgIA7TLYbzaKt8sAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for an overflow menu",
          "title": "Structure and spacing measurements for an overflow menu",
          "src": "/static/a1325d0165d9412acd03b3b842695e5e/fb070/overflow-menu-style-3.png",
          "srcSet": ["/static/a1325d0165d9412acd03b3b842695e5e/d6747/overflow-menu-style-3.png 288w", "/static/a1325d0165d9412acd03b3b842695e5e/09548/overflow-menu-style-3.png 576w", "/static/a1325d0165d9412acd03b3b842695e5e/fb070/overflow-menu-style-3.png 1152w", "/static/a1325d0165d9412acd03b3b842695e5e/c3e47/overflow-menu-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      